//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}

// Colors
[class*="bg-gray-2"] {
  background-color: $gray-2;
}

// Typography
h1 {
  &.guide {
    font-size: 82px;
    font-weight: 700;
  }
}

// Buttons
.btn {
  border-radius: $border-radius;
  font-weight: 400;
  &-primary {
    color: $white;
  }
  &-primary-2 {
    color: $white;
  }
  &-outline-primary-2 {
    &:hover {
      color: $white;
    }
  }
}

// Forms
.form-control {
  border-radius: $border-radius;
}

.form-group {
  label {
    color: $gray-900;
  }
}

// Radios

.custom-radio {
  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: none;
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $primary-2;
    }
  }

  &.onboarding {
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: $border-radius;
    padding: 20px;

    .custom-control-label {
      padding-left: 30px;
      display: block;
      font-size: 1em;

      &:before {
        left: 0;
      }

      .description {
        display: block;
        font-weight: 400;
      }
    }
  }
}

.custom-checkbox {
  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $primary-2;
    }
  }

  &.onboarding {
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: $border-radius;
    padding: 10px 20px;

    .custom-control-label {
      padding-left: 30px;
      display: block;
      &:before,
      &:after {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .description {
        display: block;
        font-weight: 400;
      }
    }
  }
}

.form-footer {
  margin-top: 30px;
}

// Header
.navbar {
  border: none;
}
.navbar-brand {
  svg,
  img {
    width: 300px;
  }
}

// Footer
footer {
  &#footer {
    padding: 1em 0;

    ul {
      &.steps {
        list-style-type: decimal;
        counter-reset: LIST-ITEMS;
        margin-bottom: 0;
        font-weight: 600;
        li {
          display: inline-block;
          &:before {
            content: counter(LIST-ITEMS) ".";
            counter-increment: LIST-ITEMS;
            padding-right: 0.25em;
          }
          &:not(:last-child) {
            margin-right: 3.55em;
          }
          &.active {
            color: $white;
          }
          svg {
            margin-left: 3px;
          }
        }
      }
    }
  }
}

// Cards - Steps
.card {
  border-radius: 0px;
  &.steps {
    border: none;

    &.card-body {
      padding-left: 0;
    }
    .checkmark {
      margin-right: 15px;
      margin-left: -47px;
    }
    .step-number {
      font-size: 3.55em;
      font-weight: 700;
      margin-bottom: 0;
    }

    .title {
      font-weight: 700;

      .meta {
        font-size: 0.75rem;
        font-weight: 400;
        font-style: italic;

        &.success {
          color: #389e0d;
        }
      }
    }
  }
  &.clipboard {
    background-color: $gray-1;

    p {
      &.clip {
        margin-bottom: 0;
      }
    }
  }
}

// Figures
figcaption {
  font-weight: 400;
}

@media #{$md-and-down} {
  section {
    padding: 3rem 0;
  }
  // Typography
  h1.guide {
    font-size: 60px;
  }
  // Header
  .navbar-brand {
    svg,
    img {
      max-width: 100%;
    }
  }

  // Cards
  .card {
    &.steps {
      &.card-body {
        padding-left: 35px;
      }
    }
  }
}

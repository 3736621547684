//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// Color Scheme
// Uncomment the variables below and change the hex values.

$primary: #6fcf97;
$primary-2: #36cfc9;
$primary-3: #4f4f4f;
$gray-1: #f5f5f5;
$gray-2: #f5faff;

$link-color: #40a9ff;
$link-hover-color: $primary-3;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
  "primary": $primary,
  "primary-2": $primary-2,
  "primary-3": $primary-3,
);

$theme-colors: (
  "primary": $primary,
  "primary-2": $primary-2,
  "primary-3": $primary-3,
);

$border-radius: 2px;

// Forms
$custom-control-indicator-checked-bg: $primary-2;
$custom-control-indicator-checked-border-color: $primary;
$component-active-bg: $primary;

// Responsive
$sm-screen: 540px;
$md-screen: 720px;
$lg-screen: 960px;
$xl-screen: 1140px;

$sm-and-down: "only screen and (max-width : #{$sm-screen})" !default;
$md-and-down: "only screen and (max-width : #{$md-screen})" !default;
$lg-and-down: "only screen and (max-width : #{$lg-screen})" !default;
$xl-and-down: "only screen and (max-width : #{$xl-screen})" !default;
